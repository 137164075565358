const makeWPCall = async (input, wpAjaxObj) => {
  // eslint-disable-line
  const data = new FormData(); // eslint-disable-line

  data.append("action", "autocompleteAjaxCallback");
  data.append("nonce", wpAjaxObj.ajax_nonce);
  data.append("search_term", input);

  try {
    const response = await fetch(wpAjaxObj.ajax_url, {
      method: "POST",
      credentials: "same-origin",
      body: data,
    });

    return await response.json();
  } catch (error) {
    console.error("error ", error);
  }
};

const getResults = async (input, wpAjaxObj) => {
  // make a fetch call to WP
  try {
    const response = await makeWPCall(input, wpAjaxObj);

    return await response;
  } catch (error) {
    return error;
  }
};

const displayResults = (data, resultsContainer) => {
  const container = resultsContainer;
  container.style.display = "block";
  container.innerHTML = "";

  data.forEach((result) => {
    container.innerHTML += `<li class="search-item"><span class="img-holder"><img src="${result.img_src}" alt="${result.label}"></span><span class="content-holder"><span class="label">${result.label}</span><span class="pricing">${result.price}</span></span></li>`;
  });
};

const resultsContainerClick = (resultsContainer, searchForm) => {
  const container = resultsContainer;
  container.onclick = (e) => {
    const input = searchForm.querySelector("input[type='search']");

    if (!input) return;

    const setValue = e.target.querySelector(".label").innerText;
    input.value = setValue;

    container.innerHTML = "";

    const searchButton = searchForm.querySelector("button[type=submit]"); // eslint-disable-line

    searchButton.click();
  };
};

const searchInputOnInput = (resultsContainer, searchInput, wpAjaxObj) => {
  const container = resultsContainer;
  const search = searchInput;
  search.oninput = (e) => {
    const userInput = e.target.value;

    container.innerHTML = "";
    container.classList.add("active-search");

    if (userInput.length < 3) {
      return;
    }

    const autocompleteSeeMore = document.querySelector(
      ".autocomplete-see-more"
    );

    if (autocompleteSeeMore && userInput !== "") {
      let autocompleteSeeMoreHref =
        autocompleteSeeMore.getAttribute("data-base-href");
      if (autocompleteSeeMoreHref && autocompleteSeeMoreHref !== "") {
        autocompleteSeeMore.setAttribute(
          "href",
          autocompleteSeeMoreHref + "?s=" + userInput
        );
      }
    }

    getResults(userInput, wpAjaxObj).then((data) => {
      displayResults(data, container);
      container.classList.remove("active-search");
    });
  };
};

window.addEventListener("load", () => {
  // eslint-disable-line
  const searchInput = document.querySelector(
    ".header-bottom-search .search-form"
  ); // eslint-disable-line
  const wpAjaxObj = autocomplete_search_ajax; // eslint-disable-line

  if (!searchInput || !wpAjaxObj || Object.keys(wpAjaxObj).length === 0) {
    return;
  }

  const resultsContainer = document.querySelector(
    ".header-bottom-search .search-results ul"
  ); // eslint-disable-line

  searchInputOnInput(resultsContainer, searchInput, wpAjaxObj);

  resultsContainerClick(resultsContainer, searchInput);
});
